.mantine-DatePicker-wrapper {
  color: #667085;
}

.mantine-DatePicker-input:focus {
  color: #667085 !important;
  border: 1px solid #667085;
  border-color: #d0d5dd !important;
  font-size: 16px;
  line-height: 26px;
  outline: none;
}

.mantine-DatePicker-input {
  border-radius: 0.5rem;
  color: #667085 !important;
  font-size: 16px;
  line-height: 26px;
  padding-left: 15px;
  padding-right: 15px;
  height: 44px;
}
.mantine-DatePicker-label {
  margin-top: 0px !important;
  font-size: 14px !important;
  line-height: 20px;
  margin-bottom: 3px;
  padding-left: 0px;
  color: #344054;
  font-weight: 500;
}

/* sm screen */
@media screen and (min-device-width: 576px) and (max-device-width: 767px) {
  .mantine-DatePicker-input {
    font-size: 12px;
    line-height: 16px;
  }
  .mantine-DatePicker-label {
    font-size: 10px;
    line-height: 16px;
    margin-bottom: 2px;
  }
}

/* md screen */
@media screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .mantine-DatePicker-input {
    font-size: 14px;
    line-height: 20px;
  }
  .mantine-DatePicker-label {
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 18px;
  }
}

/* lg screen */
@media screen and (min-device-width: 1024px) and (max-device-width: 1339px) {
  .mantine-DatePicker-input {
    padding-top: 4px ;
    padding-bottom: 4px ;
    font-size: 14px;
    line-height: 20px;
  }
  .mantine-DatePicker-label {
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 18px;
  }
}
