@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }
}
/* .css-scrollbar::-webkit-scrollbar {
  width: 5px;
}


.css-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 

.css-scrollbar::-webkit-scrollbar-thumb {
  background: #888; 
}


.css-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgb(85, 85, 85); 
} /*/

@import url("https://rsms.me/inter/inter.css");
html {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}